<template>
  <div class="break-line">
  </div>
</template>

<script>
export default {
  name: 'BreakLine'
}
</script>

<style lang="scss">
.break-line {
  height: 30px;
  background: url('../../assets/images/break-line.png') repeat-x;
  background-size: 1200px 20px;
  background-position: center;
  margin: 20px 0;
}
</style>
