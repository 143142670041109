<template>
  <div class="question-answer-view">
    <Editor
      style="overflow-y: hidden;"
      :defaultConfig="editorConfig"
      mode="default"
      @onCreated="onCreated"
    />
    <el-image-viewer v-if="imageViewerData.visible" :initial-index="imageViewerData.index" :url-list="imageViewerData.urlList" :on-close="handleImageViewerClose"></el-image-viewer>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { Editor } from '@wangeditor/editor-for-vue'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  name: 'QuestionAnswerView',
  components: { ElImageViewer, Editor },
  props: {
    value: {
      type: String
    }
  },
  data () {
    return {
      // 是否为强制滚动
      isForceScroll: false,
      // 编辑器
      editor: null,
      editorConfig: {
        readOnly: true
      },
      // 图片预览数据
      imageViewerData: {
        index: 0,
        urlList: [],
        visible: false
      }
    }
  },
  watch: {
    value (newValue) {
      this.editor.setHtml(newValue)
      // 同步图片数据
      this.syncImages()
    }
  },
  methods: {
    ...mapMutations(['topFavoriteEmoji']),
    onCreated (editor) {
      // 一定要用 Object.seal() ，否则会报错
      this.editor = Object.seal(editor)
      this.editor.setHtml(this.value)
      // 同步图片数据
      this.syncImages()
      // 初始化事件
      this.initEvents()
    },
    // 同步图片数据
    syncImages () {
      this.imageViewerData.urlList = []
      this.$nextTick(() => {
        const editorImages = this.$el.querySelectorAll('img')
        if (editorImages == null || editorImages.length === 0) {
          return
        }
        for (let i = 0; i < editorImages.length; i++) {
          // 为编辑器中的图片绑定点击事件
          editorImages[i].addEventListener('click', () => {
            this.imageViewerData.index = i
            this.imageViewerData.visible = true
          })
          // 获取图片链接
          this.imageViewerData.urlList.push(editorImages[i].src)
        }
      })
    },
    // 关闭图片预览
    handleImageViewerClose () {
      this.imageViewerData.visible = false
    },
    // 滚动至指定标题
    scrollToTitle (catalog) {
      this.isForceScroll = true
      document.querySelector('#app').scrollTop = catalog.top - 30
      this.$emit('catalog-change', catalog)
    },
    // 初始化编辑器时间
    initEvents () {
      const hs = this.$el.querySelectorAll('h1,h2')
      const titleList = []
      for (let i = 0; i < hs.length; i++) {
        titleList.push({
          id: hs[i].id,
          tag: hs[i].tagName,
          title: hs[i].innerText,
          top: hs[i].getBoundingClientRect().top
        })
      }
      this.$emit('catalog-prepared', titleList)
      this.$emit('catalog-change', titleList[0])
      document.querySelector('#app').addEventListener('scroll', event => {
        if (this.isForceScroll) {
          this.isForceScroll = false
          return
        }
        for (let i = titleList.length - 1; i >= 0; i--) {
          const title = titleList[i]
          if (event.target.scrollTop > title.top - 200) {
            this.$emit('catalog-change', title)
            break
          }
        }
      })
    }
  },
  // 组件销毁时，及时销毁编辑器
  beforeDestroy () {
    const editor = this.editor
    if (editor == null) return
    editor.destroy()
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/variables";
.question-answer-view {
  // 文本编辑
  /deep/ .w-e-text-container {
    font-size: 17px;
    letter-spacing: 1px;
    // 标题
    h1 {
      font-size: 20px;
      position: relative;
      padding-left: 20px;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 5px;
        border-style: solid;
        border-width: 8px;
        border-color: transparent;
        border-left-color: $primary-color;
      }
    }
    h2 {
      font-size: 20px;
      font-weight: bold;
    }
    h3 {
      font-size: 16px;
    }
    // 无序列表
    ul {
      list-style: none;
      li {
        position: relative;
        &::before {
          display: block;
          position: absolute;
          top: 10px;
          left: -20px;
          content: '';
          width: 10px;
          height: 10px;
          background-color: orange;
          transition: all ease .3s;
          opacity: .5;
          border-radius: 3px;
        }
        &:hover::before {
          opacity: 1;
        }
      }
    }
    // 有序列表
    ol {
      padding-left: 30px;
    }
    // 表格
    .table-container {
      padding: 0;
      border: 0;
      table {
        border-color: #eee;
      }
      th {
        border: 0 !important;
        background-color: transparent;
        font-size: 14px;
        line-height: 22px;
        border-color: #eee;
        color: $font-color-third;
      }
      td {
        border-color: #eee;
        font-size: 15px;
      }
    }
    // 分割线
    .w-e-textarea-divider {
      padding: 20px 0;
      hr {
        background-color: $background-color-second - 10;
      }
    }
    // 设置行高
    .blockquote, li, p, td, th {
      line-height: 40px;
    }
    // 代码
    pre code {
      border: 0;
      background-color: #f7f7f7;
      border-radius: 10px;
      box-shadow: 0 0 10px -6px #333;
    }
    // 引用
    blockquote {
      background-color: transparent;
      border: 0;
      & > span {
        position: relative;
        font-weight: bold;
        [data-slate-string] {
          padding: 0 20px;
        }
        &::before {
          font-family: pipafont;
          content: '\e61d';
          position: absolute;
          top: -10px;
          left: -10px;
          color: #ccc;
        }
        &::after {
          font-family: pipafont;
          content: '\e61d';
          position: absolute;
          bottom: -5px;
          right: -10px;
          color: #ccc;
          transform: rotate(180deg);
        }
      }
    }
    // 选中效果
    [data-slate-editor] [data-selected=true] {
      box-shadow: none;
    }
  }
  // 图片外壳
  /deep/ .w-e-image-container {
    margin: 20px 0 !important;
    width: 100%;
    text-align: center;
    // 去掉图片拖拽改变大小的处理
    .w-e-image-dragger {
      display: none;
    }
    &:hover {
      box-shadow: none;
    }
    img {
      max-width: 65%;
      cursor: zoom-in;
    }
  }
  // 工具栏
  /deep/ .w-e-bar {
    display: none;
  }
}
</style>
